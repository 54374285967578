import React from "react"
import { returnContentfulMediaType } from "../utils/helpers"
import Img from "gatsby-image"

const HeroMediaComponent = ({ media }) => {
  const mediaType = returnContentfulMediaType(media)

  console.log({ mediaType, media })

  if (mediaType === "image") {
    return (
      <Img
        className="home-hero__image"
        loading="eager"
        fluid={media.localFile.childImageSharp.fluid}
      />
    )
  }
  if (mediaType === "video") {
    const videoUrl = media.localFile.publicURL
    const contentfulVideoUrl = media.file.url
    return (
      <video
        className="home-hero__video"
        src={contentfulVideoUrl}
        autoPlay
        muted
        loop
        onLoadedData={() => console.log("ready")}
      ></video>
    )
  }
  return <div>Media missing</div>
}

export default HeroMediaComponent
